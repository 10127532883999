module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<article class=\"grid-view-item\">\n    <a class=\"link\" href=\"")
    ; __append(escapeFn( href ))
    ; __append("\" aa-link=\"")
    ; __append(escapeFn( aaLink ))
    ; __append("\"\n        ")
    ;  if(actionTarget) { 
    ; __append("\n            target=\"")
    ; __append(escapeFn( actionTarget ))
    ; __append("\"\n        ")
    ;  } 
    ; __append("\n    >\n        <div class=\"thumb-wrapper\">\n            <img class=\"thumb lazy\"\n                 data-src=\"")
    ; __append(escapeFn( thumb ))
    ; __append("\"\n                 alt=\"")
    ; __append(escapeFn( alt ))
    ; __append("\"/>\n            ")
    ;  if(icon){ 
    ; __append("\n                <div class=\"label ")
    ; __append(escapeFn( icon ))
    ; __append(" circle-sm\"></div>\n            ")
    ;  } 
    ; __append("\n        </div>\n    </a>\n    <div class=\"info-wrapper\">\n        <span class=\"meta-category\">\n            ")
    ; __append(escapeFn( contentType ))
    ; __append("\n        </span>\n        <div class=\"title-wrapper\">\n            <span class=\"title\">\n                ")
    ; __append(escapeFn( title ))
    ; __append("\n            </span>\n        </div>\n    </div>\n</article>")
  }
  return __output;

}