module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("\n<article class=\"grid-view-item\">\n    <a class=\"link\"\n       href=\"")
    ; __append(escapeFn( url ))
    ; __append("\"\n       aa-link=\"")
    ; __append(escapeFn( aaLink ))
    ; __append("\"\n        ")
    ;  if (typeof dataTracking !== 'undefined') {  
    ; __append("\n            data-tracking=\"")
    ; __append(escapeFn( dataTracking ))
    ; __append("\">\n        ")
    ;  } 
    ; __append("\n    >\n        <div class=\"thumb-wrapper\">\n\n            <img class=\"thumb lazy\" data-src=\"")
    ; __append(escapeFn( thumbUrl ))
    ; __append("\"\n                 alt=\"")
    ; __append(escapeFn( title ))
    ; __append("\"\n            />\n        </div>\n    </a>\n\n    <div class=\"info-wrapper\">\n\n\n        ")
    ; __append("\n        ")
    ;  if(bioType == 'host'){ 
    ; __append("\n        <div class=\"title-wrapper\">\n            <span class=\"meta-gray\">\n                Host\n            </span>\n        </div>\n        ")
    ;  } 
    ; __append("\n\n\n        ")
    ; __append("\n        ")
    ;  if(characterName){ 
    ; __append("\n        <div class=\"title-wrapper\">\n            <span class=\"meta-gray\">\n                ")
    ; __append(escapeFn( characterName ))
    ; __append("\n            </span>\n        </div>\n        ")
    ;  } 
    ; __append("\n\n\n\n        ")
    ; __append("\n        ")
    ;  if(title){ 
    ; __append("\n        <div class=\"title-wrapper\">\n            <span class=\"title\">\n                ")
    ; __append(escapeFn( title ))
    ; __append("\n            </span>\n        </div>\n        ")
    ;  } 
    ; __append("\n\n    </div>\n</article>")
  }
  return __output;

}