module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ;  schedules.forEach(function(schedule) { 
    ; __append("\n\n    <div class=\"scheduleHeader padded-container\">\n        <span>")
    ; __append(escapeFn( schedule.header.label ))
    ; __append("</span>\n        <span>")
    ; __append(escapeFn( schedule.header.labelMobile ))
    ; __append("</span>\n    </div>\n\n    ")
    ;  schedule.matches.forEach(function(match) { 
    ; __append("\n        <div class=\"grid-wrapper\">\n            ")
    ;  if((match.awayTeamCity) && (match.homeTeamCity)) { 
    ; __append("\n            <div class=\"away-bg ")
    ; __append(escapeFn( match.awayTeamCSSName ))
    ; __append("\"></div>\n            <div class=\"home-bg ")
    ; __append(escapeFn( match.homeTeamCSSName ))
    ; __append("\"></div>\n            <div class=\"padded-container\">\n                <ul class=\"grid footballSchedule\">\n                    <li>\n                        <span class=\"teamCity\">")
    ; __append(escapeFn( match.awayTeamCity ))
    ; __append("</span>\n                        <span class=\"teamName\">")
    ; __append(escapeFn( match.awayTeamName ))
    ; __append("</span>\n                    </li>\n                    <li class=\"")
    ; __append(escapeFn( match.awayTeamCSSName ))
    ; __append("\">\n                        <img src=\"")
    ; __append(escapeFn( match.awayLogo ))
    ; __append("\">\n                    </li>\n                    <li class=\"")
    ; __append(escapeFn( match.homeTeamCSSName ))
    ; __append("\">\n                        <img src=\"")
    ; __append(escapeFn( match.homeLogo ))
    ; __append("\">\n                    </li>\n                    <li>\n                        <span class=\"teamCity\">")
    ; __append(escapeFn( match.homeTeamCity ))
    ; __append("</span>\n                        <span class=\"teamName\">")
    ; __append(escapeFn( match.homeTeamName ))
    ; __append("</span>\n                    </li>\n                </ul>\n                </div>\n            <div class=\"square-wrapper\">\n                <div class=\"square\">\n                    <span>AT</span>\n                </div>\n            </div>\n            <a class=\"button terciary small\" href=\"")
    ; __append(escapeFn( match.gameLink ))
    ; __append("\" target=\"_blank\" rel=\"noopener\">\n                ")
    ; __append(escapeFn( match.gameLinkCopy ))
    ; __append("\n            </a>\n            ")
    ;  } else { 
    ; __append("\n            <div class=\"padded-container\">\n                <ul class=\"grid footballSchedule\">\n                    <li class=\"tbd\"><span>Teams To Be Determined<span></li>\n                </ul>\n            </div>\n            ")
    ;  } 
    ; __append("           \n        </div>\n    ")
    ;  }); 
    ; __append("\n")
    ;  }); 
  }
  return __output;

}