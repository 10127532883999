module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<article class=\"grid-view-item\">\n\n        <div class=\"thumb-wrapper\">\n            <img class=\"thumb lazy\" data-src=\"")
    ; __append(escapeFn( thumbUrl ))
    ; __append("\" alt=\"")
    ; __append(escapeFn( show_title ))
    ; __append(" - ")
    ; __append(escapeFn( title ))
    ; __append("\"/>\n        </div>\n\n    <meta itemprop=\"name\" content=\"")
    ; __append(escapeFn( title ))
    ; __append("\"/>\n    <meta itemprop=\"description\" content=\"")
    ; __append(escapeFn( alt_title ))
    ; __append("\"/>\n    <div class=\"info-wrapper\">\n        <div class=\"title-wrapper\">\n            <span class=\"title\">\n                    ")
    ; __append(escapeFn( title ))
    ; __append("\n            </span>\n        </div>\n    </div>\n</article>")
  }
  return __output;

}